import axios from "axios"
import BASE_URL from "./auth/Baseurl";

const getProfile = async() => {
    const response = await axios.get(`${BASE_URL}/current-user`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  };

  


export default getProfile