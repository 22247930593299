import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Home from "./Pages/Home";
import Listen from "./Pages/Listen";
import Booking from "./Pages/Booking";
import Profile from "./Pages/Profile";
import Changepassword from "./Pages/Changepassword";
import Details from "./Pages/Details";
import MyListing from "./Pages/MyListing";
import EditListing from "./Pages/EditListing";
import ThemProvider from "./context/ContextFile";
import Messages from "./Pages/Message";
import AddListing from "./Pages/AddListing";
import About from "./Pages/About";
import SignIn from "./Pages/SignIn";
import Subscribe from "./Pages/Subscribe";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import TermsAndCondition from "./Components/Terms-And-Condition";
import ContactUs from "./Components/ContactUs";
// import PaypalPlan from "./Pages/PaypalPlan";

function App({ children }) {

  return (
    <>
      <ThemProvider>
        {children}
        <BrowserRouter>
          <Routes>
            <Route path="/dashboard" element={<Listen />} />
            {/* <Route path="/paypal_plan" element={<PaypalPlan />} /> */}
            <Route path="/" element={<SignIn />} />
            <Route path="/changepassword" element={<Changepassword />} />
            <Route path="/About" element={<About />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/my_listing" element={<MyListing />} />
            <Route path="/addlisting" element={<AddListing />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/subscribe" element={<Subscribe />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/details/:id" element={<Details />} />
            <Route path="/EditListing/:id" element={<EditListing />} />
            {/* <Route path="/home" element={<Home />} /> */}
          </Routes>
        </BrowserRouter>
      </ThemProvider>
    </>
  );
}

export default App;
