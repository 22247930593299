/* eslint-disable */
import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import BannerDashboard from "../Components/BannerDashboard";
import Layout from "../Components/layout/Layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BASE_URL from "../Components/auth/Baseurl";
import axios from "axios";

// import loader from '/image/loading.webp'



function Listen() {

const [modalIsOpen, setIsOpen] = useState(false);
const [loader , setLoader] = useState(false)
// const [loader2 , setLoader2] = useState(false)
const location = useLocation();
const navigation = useNavigate()





// const subscribeCheck = async()=>{

//   axios.get(`${BASE_URL}/subscribe-check`, {
//     headers: {
//       "Content-Type": "multipart/form-data",
//       Authorization: `Bearer ${localStorage.getItem("token")}`,
//     },
//   })
//   .then((res) => {
//     console.log(res.data,'subscribeCheck');
//     setsubscribetion(res?.data?.status)
//   })
//   .catch((err) => {
//     console.log(err?.response?.data?.status,'subscribeCheck');
//     setsubscribetion(err.response.data.status)
//   });

// };

// useEffect(()=>{
//   subscribeCheck()
//   if(location.search == '?status=true&message=Successfully%20Subscribe'){
//     Swal.fire({
//       title: "Your Subscription is Activated",
//       icon: "success"
//     });
//   }
// },[])

// const SubscribeMonthlyDeal =()=>{

//   !subscribetion ? (

//     axios.get(`${BASE_URL}/subscribe`, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//         Authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//     }).then((res) => {
//       console.log(res?.data?.url,'responce')
//       window.location.href = res?.data?.url

//     }).catch((err) => {
//       // setIsOpen(err?.response?.data.status)
//       console.log(err,'responce')

//     })

//   ) : null
// }










  return (
<>
      <div id="wrapper">
        {/* content*/}
        
        <div className="content">
          {/*  section  */}

          <BannerDashboard />
          {/*  section  end*/}
          {/*  section  */}
          <section className="gray-bg main-dashboard-sec" id="sec1">
            <div className="container" style={{
              minHeight:'40vh'
            }}>
              {/*  dashboard-menu*/}
              
                 <Sidebar /> 
                
              
              
              {/* dashboard-menu  end*/}
              {/* dashboard content*/}
       
              {/* dashboard content end*/}
            </div>
          </section>
          {/*  section  end*/}
          <div className="limit-box fl-wrap" />
        </div>
        {/*content end*/}
      </div>


</>

       

  );
}

export default Listen;