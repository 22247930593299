/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-key */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from "react";
// import DeleteIcon from '@mui/icons-material/Delete';
import "./message.css";
import BASE_URL from "./auth/Baseurl";
import axios from "axios";

const Message = () => {
  const currentUser = JSON.parse(localStorage.getItem("data"));

  const [allChat, setAllChat] = useState([]);
  const [getChatById, setGetChatById] = useState([]);
  const [chatValue, setChatValue] = useState("");
  const [chatId, setChatId] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleIconClick = () => {
    document.getElementById("imageUpload").click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSendImage = () => {
    if (selectedImage) {
      setSelectedImage(null);
      setImagePreview(null);
    }
  };

  const getAllChat = async () => {
    const response = await axios.get(`${BASE_URL}/all-chats`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response?.data;
  };

  const handlerChatById = async (id) => {
    setChatId(id);
    const response = await axios.get(`${BASE_URL}/get-chat/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    setGetChatById(response?.data?.messages);
  };

  const handlerSubmitChat = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("message", chatValue);
    formData.append("chat_id", chatId);
    if (selectedImage) {
      formData.append("image", selectedImage);
    }
     axios.post(`${BASE_URL}/message-send`, formData, {
      headers:{
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        console.log(response, "response");
        setChatValue("");
        handlerChatById(chatId);
        handleSendImage();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllChat()
      .then((data) => {
        setAllChat(data?.chat);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="app">
        <div className="header">
          <div className="search-bar">
            <input type="text" placeholder="Search..." />
          </div>
          <div className="user-settings">
            <div className="dark-light">
              <svg
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="1.5"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z" />
              </svg>
            </div>
            <div className="settings">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx={12} cy={12} r={3} />
                <path d="M19.4 15a1.65 1.65 0 00.33 1.82l.06.06a2 2 0 010 2.83 2 2 0 01-2.83 0l-.06-.06a1.65 1.65 0 00-1.82-.33 1.65 1.65 0 00-1 1.51V21a2 2 0 01-2 2 2 2 0 01-2-2v-.09A1.65 1.65 0 009 19.4a1.65 1.65 0 00-1.82.33l-.06.06a2 2 0 01-2.83 0 2 2 0 010-2.83l.06-.06a1.65 1.65 0 00.33-1.82 1.65 1.65 0 00-1.51-1H3a2 2 0 01-2-2 2 2 0 012-2h.09A1.65 1.65 0 004.6 9a1.65 1.65 0 00-.33-1.82l-.06-.06a2 2 0 010-2.83 2 2 0 012.83 0l.06.06a1.65 1.65 0 001.82.33H9a1.65 1.65 0 001-1.51V3a2 2 0 012-2 2 2 0 012 2v.09a1.65 1.65 0 001 1.51 1.65 1.65 0 001.82-.33l.06-.06a2 2 0 012.83 0 2 2 0 010 2.83l-.06.06a1.65 1.65 0 00-.33 1.82V9a1.65 1.65 0 001.51 1H21a2 2 0 012 2 2 2 0 01-2 2h-.09a1.65 1.65 0 00-1.51 1z" />
              </svg>
            </div>
            <img
              className="user-profile"
              src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%281%29.png"
              alt=""
            />
          </div>
        </div>
        <div className="wrapper">
          <div className="conversation-area">
            {allChat?.map((chat, index) => (
              <div
                className="msg online"
                key={index}
                onClick={() => handlerChatById(chat?.id)}
              >
                <img
                  className="msg-profile"
                  src={
                    chat?.sender_chat?.image
                      ? chat?.sender_chat?.image
                      : "https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%281%29.png"
                  }
                  alt=""
                />
                <div className="msg-detail">
                  <div className="msg-username">
                    {chat?.sender_chat?.first_name +
                      " " +
                      chat?.sender_chat?.last_name}
                  </div>
                  <div className="msg-content">
                    <span className="msg-message">{chat?.first_message}</span>
                    <span className="msg-date">20m</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="chat-area pt-5">
            <div className="chat-area-main">
              {getChatById?.map((message, index) => (
                <>
                  {currentUser?.id === message?.sender_id ? (
                    <>
                      {/* Right Side */}
                      <div className="chat-msg owner" key={index}>
                        <div className="chat-msg-profile">
                          <img
                            className="chat-msg-img"
                            src={
                              message?.sender?.image
                                ? message?.sender?.image
                                : "https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%281%29.png"
                            }
                            alt=""
                          />
                          <div className="chat-msg-date">
                            Message seen 1.22pm
                          </div>
                        </div>
                        <div className="chat-msg-content">
                          <div className="chat-msg-text">
                            {message?.message}
                          </div>
                        </div>
                      </div>
                      {message?.image && (
                        <div style={{display:"flex",justifyContent:"end"}} className="d-flex justify-content-end mb-4 me-4">
                          <div style={{width:"50%"}} className="chat-msg-text w-50">
                            <img src={message?.image} />
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {/* Left Side */}
                      <div className="chat-msg">
                        <div className="chat-msg-profile">
                          <img
                            className="chat-msg-img"
                            src={
                              message?.sender?.image
                                ? message?.sender?.image
                                : "https://s3-us-west-2.amazonaws.com/s.cdpn.io/3364143/download+%281%29.png"
                            }
                            alt=""
                          />
                          <div className="chat-msg-date">
                            Message seen 2.45pm
                          </div>
                        </div>
                        <div className="chat-msg-content">
                          <div className="chat-msg-text">
                            {message?.message}
                          </div>
                        </div>
                      </div>
                      {message?.image && (
                        <div style={{display:"flex",justifyContent:"start"}} className="d-flex justify-content-start mb-4 me-4">
                          <div style={{width:"50%"}} className="chat-msg-text w-50 ">
                            <img src={message?.image} />
                          </div>
                        </div>
                      )}
                    </>
                  )}  
                </>
              ))}
            </div>
            {getChatById?.length > 0 && (
              <form onSubmit={handlerSubmitChat}>
                {imagePreview && (
                  <div className="image-preview">
                    <img src={imagePreview} alt="Selected" />
                  </div>
                )}
                <div className="chat-area-footer">
                  {imagePreview ? (
                    <>
                      {/* <DeleteIcon color="error" onClick={handleSendImage} /> */}
                    </>
                  ) : (
                    <>
                      <div className="image-upload-section">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-image"
                          onClick={handleIconClick}
                        >
                          <rect
                            x={3}
                            y={3}
                            width={18}
                            height={18}
                            rx={2}
                            ry={2}
                          />
                          <circle cx="8.5" cy="8.5" r="1.5" />
                          <path d="M21 15l-5-5L5 21" />
                        </svg>
                        <input
                          type="file"
                          id="imageUpload"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={handleImageChange}
                        />
                      </div>
                    </>
                  )}
                  <input
                    type="text"
                    placeholder="Type something here..."
                    value={chatValue}
                    required
                    onChange={(e) => setChatValue(e.target.value)}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-smile"
                  >
                    <circle cx={12} cy={12} r={10} />
                    <path d="M8 14s1.5 2 4 2 4-2 4-2M9 9h.01M15 9h.01" />
                  </svg>
                  <button
                    className="ms-4"
                    type="submit"
                    style={{ border: "none", background: "none" }}
                  >
                    <svg
                      viewBox="0 0 513 513"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256.025.05C117.67-2.678 3.184 107.038.025 245.383a240.703 240.703 0 0085.333 182.613v73.387c0 5.891 4.776 10.667 10.667 10.667a10.67 10.67 0 005.653-1.621l59.456-37.141a264.142 264.142 0 0094.891 17.429c138.355 2.728 252.841-106.988 256-245.333C508.866 107.038 394.38-2.678 256.025.05z" />
                      <path
                        d="M330.518 131.099l-213.825 130.08c-7.387 4.494-5.74 15.711 2.656 17.97l72.009 19.374a9.88 9.88 0 007.703-1.094l32.882-20.003-10.113 37.136a9.88 9.88 0 001.083 7.704l38.561 63.826c4.488 7.427 15.726 5.936 18.003-2.425l65.764-241.49c2.337-8.582-7.092-15.72-14.723-11.078zM266.44 356.177l-24.415-40.411 15.544-57.074c2.336-8.581-7.093-15.719-14.723-11.078l-50.536 30.744-45.592-12.266L319.616 160.91 266.44 356.177z"
                        fill="#fff"
                      />
                    </svg>
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Message;
