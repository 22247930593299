// eslint-disable-next-line
// eslint-disable-next-line no-unused-vars
/* eslint-disable */
import React, { useEffect, useState } from "react";
import BASE_URL from "../../Components/auth/Baseurl";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, json, useNavigate, useParams } from "react-router-dom";
import Loading from "../loader/Loading";
import ImageUploading from "react-images-uploading";
import BtnLoader from "../BtnLoader/BtnLoader";

function Edit() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [listing, setListing] = useState([]);
  const [extraServicePrices, setExtraServicePrices] = useState([]);
  const [listing_Beds, setListing_Beds] = useState([]);
  const [listing_Images, setListing_Images] = useState([]);
  const [loader, setLoader] = useState(false);
  const [get, setGet] = useState([]);
  const [getone, setGetone] = useState([]);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    type_of_space: "",
    listing_type: "",
    no_of_bedroom: "",
    no_of_guest: "",
    no_of_bed: "",
    no_of_bathroom: "",
    no_of_room: "",
    pet_allowed: "",
    size: "",
    instant_booking: 0,
    unit_of_measure: "",
    addi_info: "",
    nightly: "",
    smoking_allowed: "",
    check_in_after: "",
    check_in_before: "",
    per: "",
    house_rule_addi_info: "",
    weekend: "",
    cancellation_policy: "",
    apply_weekend_price: "",
    min_day_booking: "",
    max_day_booking: "",
    weekly_seven_plus: "",
    weekly_thirty_plus: "",
    extra_service_prices: "",
    addi_guest: "",
    addi_guest_price: "",
    no_of_addi_guest: "",
    party_allowed: "",
    children_allowed: "",
    clean_fee: "",
    clean_fee_daily: "",
    clean_fee_per_stay: "",
    city_fee: "",
    city_fee_daily: "",
    city_fee_per_stay: "",
    security_deposit: "",
    tax: "",
    amenities: [],
    facilities: [],
    address: "",
    apt_suite: "",
    city: "",
    state: "",
    zipcode: "",
    area: "",
    country: "",
  });
  // const valueGetFacilities = (e) => {
  //   const { name, checked } = e.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     facilities: checked
  //       ? [...prevState.facilities, name]
  //       : prevState.facilities.filter((item) => item !== name),
  //   }));
  // };
  // const valueGetAmenities = (e) => {
  //   const { name, checked } = e.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     amenities: checked
  //       ? [...prevState.amenities, name] // Add facility if checked
  //       : prevState.amenities.filter((item) => item !== name), // Remove facility if unchecked
  //   }));
  // };
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? (checked ? 1 : 0) : value;

    setFormData((prevState) => ({
      ...prevState,
      [name]: inputValue,
    }));
  };
  const handleImageChange = (e) => {
    const files = e.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      images: files,
    }));
  };
  const handleInputChange1 = (e) => {
    const { name, value } = e.target;

    // Update formData state
    setFormData((prevFormData) => ({
      ...prevFormData,
      city_fee_daily: name === "city_fee_daily" && value === "Daily",
      city_fee_per_stay: name === "city_fee_per_stay" && value === "Per Stay",
    }));
  };

  const handleInputChange2 = (e) => {
    const { name, value } = e.target;

    // Update formData state
    setFormData((prevFormData) => ({
      ...prevFormData,
      clean_fee_daily: name === "clean_fee_daily" && value === "Daily",
      clean_fee_per_stay: name === "clean_fee_per_stay" && value === "Per Stay",
    }));
  };
  const Navigate = useNavigate();
  const GetData = () => {
    axios
      .get(`${BASE_URL}/listing/${id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        // console.log(res,">>>>");
        // setFormData(res?.data?.listing);
        setExtraServicePrices(res?.data?.listing?.listing_extra_service_prices);
        setListing_Beds(res?.data?.listing?.listing_beds);
        setListing_Images(res?.data?.listing?.listing_images);
        setFormData(res?.data?.listing);
        // console.log(res?.data?.listing.facilities, ">>>>>>>>>>>>>");s
        setGet(JSON.parse(res?.data?.listing?.facilities));
        setGetone(JSON.parse(res?.data?.listing?.amenities));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Multiple Input Code Start Edit
  // State for storing multiple inputs
  // State for storing multiple sets of bedroom data

  const handlePlusClickBeds = () => {
    setListing_Beds([
      ...listing_Beds,
      { name: "", no_of_guest: "", no_of_bed: "", bed_type: "", id: null },
    ]);
  };

  const handleMinusClickBeds = (index, bedroom) => {
    console.log("bedroom", bedroom?.id);
    if (listing_Beds?.length > 1) {
      const newBedrooms = [...listing_Beds];
      newBedrooms?.splice(index, 1);
      setListing_Beds(newBedrooms);
    }
    axios
      .delete(`${BASE_URL}/listing-bedroom-destroy/${bedroom?.id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleInputChangeBeds = (value, index, field, id) => {
    const newBedrooms = [...listing_Beds];
    newBedrooms[index][field] = value;
    setListing_Beds(newBedrooms);
  };

  // Multiple Input Code End Edit

  // Image Multiple Code Start

  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex, id) => {
    const updated = {
      ...imageList[addUpdateIndex[0]],
      ...listing_Images[addUpdateIndex[0]],
    };
    imageList[addUpdateIndex[0]] = updated;
    setListing_Images(imageList);
  };

  const onImageRemoves = (index, image) => {
    const updatedImages = [...listing_Images];
    updatedImages.splice(index, 1);
    setListing_Images(updatedImages);
    axios
      .delete(`${BASE_URL}/listing-image-destroy/${image?.id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Image Multiple Code End

  // Multiple Input Two Code Start Edit

  const handleEdit = (index, field, value, id) => {
    const updatedPrices = [...extraServicePrices];
    updatedPrices[index][field] = value;
    setExtraServicePrices(updatedPrices);
  };

  // Function to add a new service price
  const handleAdd = () => {
    setExtraServicePrices([
      ...extraServicePrices,
      { name: "", price: "", type: "", id: null },
    ]);
  };

  // Function to remove a service price
  const handleRemove = (index, service) => {
    const updatedPrices = extraServicePrices.filter((_, i) => i !== index);
    setExtraServicePrices(updatedPrices);
    axios
      .delete(
        `${BASE_URL}/listing-extra-service-price-destroy/${service?.id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Multiple Input Two Code End Edit

  useEffect(() => {
    GetData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    const valueGet = formData.addi_guest === "1" ? 1 : 0;
    const valueGet1 = formData.children_allowed == "True" ? 1 : 0;
    const valueGet2 = formData.city_fee_daily == "true" ? 1 : 0;
    const valueGet3 = formData.city_fee_per_stay == "false" ? 0 : 1;
    const valueGet4 = formData.clean_fee_daily == "true" ? 1 : 0;
    const valueGet5 = formData.clean_fee_per_stay == "false" ? 0 : 1;
    const valueGet6 = formData.party_allowed == "No" ? 0 : 1;
    const valueGet7 = formData.smoking_allowed == "No" ? 0 : 1;
    const valueGet8 = formData.pet_allowed == "No" ? 0 : 1;
    const Data = new FormData();
    console.log(Data, "data value>>>>>>>>");
    Data.append("title", formData.title);
    Data.append("description", formData.description);
    Data.append("type_of_space", formData.type_of_space);
    Data.append("listing_type", formData.listing_type);
    Data.append("no_of_bedroom", Number(formData.no_of_bedroom));
    Data.append("no_of_guest", formData.no_of_guest);
    Data.append("no_of_bed", Number(formData.no_of_bed));
    Data.append("no_of_bathroom", formData.no_of_bathroom);
    Data.append("no_of_room", formData.no_of_room);
    Data.append("size", formData.size);
    Data.append("instant_booking", formData.instant_booking);
    Data.append("unit_of_measure", formData.unit_of_measure);
    Data.append("addi_info", formData.addi_info);
    Data.append("nightly", formData.nightly);
    Data.append("per", formData.per);
    Data.append("address", formData.address);
    Data.append("apt_suite", formData.apt_suite);
    Data.append("city", formData.city);
    Data.append("state", formData.state);
    Data.append("zipcode", formData.zipcode);
    Data.append("city_fee", formData.city_fee);
    Data.append("city_fee_daily", valueGet2);
    Data.append("city_fee_per_stay", valueGet3);
    Data.append("area", formData.area);
    Data.append("smoking_allowed", valueGet7);
    Data.append("pet_allowed", valueGet8);
    Data.append("party_allowed", valueGet6);
    Data.append("children_allowed", valueGet1);
    Data.append("house_rule_addi_info", formData.house_rule_addi_info);
    Data.append("security_deposit", formData.security_deposit);
    Data.append("tax", formData.tax);
    Data.append("cancellation_policy", formData.cancellation_policy);
    Data.append("min_day_booking", formData.min_day_booking);
    Data.append("max_day_booking", formData.max_day_booking);
    Data.append("check_in_before", formData.check_in_before);
    Data.append("check_in_after", formData.check_in_after);
    Data.append("country", formData.country);
    Data.append("weekend", formData.weekend);
    Data.append("addi_guest_price", formData.addi_guest_price);
    Data.append("no_of_addi_guest", formData.no_of_addi_guest);
    Data.append("addi_guest", valueGet);
    Data.append("apply_weekend_price", formData.apply_weekend_price);
    Data.append("weekly_seven_plus", formData.weekly_seven_plus);
    Data.append("weekly_thirty_plus", formData.weekly_thirty_plus);
    Data.append("facilities", JSON.stringify(formData.facilities));
    Data.append("amenities", JSON.stringify(formData.amenities));
    Data.append("clean_fee_daily", valueGet4);
    Data.append("clean_fee_per_stay", valueGet5);

    listing_Beds?.map((item, index) => {
      Data.append(`bedrooms[${index}][id]`, item?.id ? item?.id : "");
      Data.append(`bedrooms[${index}][name]`, item?.name);
      Data.append(`bedrooms[${index}][no_of_guest]`, item?.no_of_guest);
      Data.append(`bedrooms[${index}][no_of_bed]`, item?.no_of_bed);
      Data.append(`bedrooms[${index}][bed_type]`, item?.bed_type);
    });

    listing_Images?.map((item, index) => {
      if ((item?.file && item?.id) || (item?.file && item?.id == undefined)) {
        Data.append(`images[${index}][id]`, item?.id ? item?.id : "");
        Data.append(`images[${index}][file]`, item?.file);
      }
    });

    extraServicePrices?.map((item, index) => {
      Data.append(
        `extra_service_prices[${index}][id]`,
        item?.id ? item?.id : ""
      );
      Data.append(`extra_service_prices[${index}][name]`, item?.name);
      Data.append(`extra_service_prices[${index}][price]`, item?.price);
      Data.append(`extra_service_prices[${index}][type]`, item?.type);
    });

    try {
      const response = await axios.post(`${BASE_URL}/listing/${id}`, Data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Http-Method-Override": "PUT",
        },
      });
      console.log(response.data);
      toast.success("Listing has been Updated successfully"); // Log any errors
      setLoader(false);
      Navigate("/my_listing");
    } catch (error) {
      console.log(error.response.data?.message);
      setLoader(false);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="col-md-9">
            <div className="dashboard-title   fl-wrap">
              <h3>Edit Listing</h3>
            </div>

            <div className="profile-edit-container fl-wrap  block_box">
              <div className="custom-form add_room-item-wrap">
                <div className="add_room-container fl-wrap">
                  {/*add_room-item end  */}
                  <div className="add_room-item fl-wrap">
                    <span
                      className="remove-rp tolt"
                      data-microtip-position="left"
                      data-tooltip="Remove"
                    >
                      <i className="fal fa-times-circle" />
                    </span>
                    <div className="row">
                      <h3
                        style={{
                          textAlign: "start",
                          margin: "15px",
                          color: "#878C9F",
                        }}
                      >
                        What type of space would you like to list?*
                      </h3>
                      <div className="col-md-4">
                        <div
                          className="profile-edit-container fl-wrap block_box"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="custom-form"
                            style={{ paddingLeft: "20px", paddingTop: "10px" }}
                          >
                            {/* Checkboxes */}
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <li>
                                <input
                                  id="entirePlace"
                                  type="radio"
                                  name="type_of_space"
                                  value="Entire Place"
                                  checked={
                                    formData.type_of_space === "Entire Place"
                                  }
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="entirePlace">
                                  Entire Place
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="profile-edit-container fl-wrap block_box"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="custom-form"
                            style={{ paddingLeft: "20px", paddingTop: "10px" }}
                          >
                            {/* Checkboxes */}
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <li>
                                <input
                                  id="privateRoom"
                                  type="radio"
                                  name="type_of_space"
                                  value="Private Room"
                                  checked={
                                    formData.type_of_space === "Private Room"
                                  }
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="privateRoom">
                                  Private Room
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="profile-edit-container fl-wrap block_box"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="custom-form"
                            style={{ paddingLeft: "20px", paddingTop: "10px" }}
                          >
                            {/* Checkboxes */}
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <input
                                id="sharedRoom"
                                type="radio"
                                name="type_of_space"
                                value="Shared Room"
                                checked={
                                  formData.type_of_space === "Shared Room"
                                }
                                onChange={handleInputChange}
                              />
                              <label htmlFor="sharedRoom">Shared Room</label>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label>
                          Title
                          <i className="fal fa-briefcase" />
                        </label>
                        <input
                          type="text"
                          placeholder="Title"
                          name="title"
                          defaultValue=""
                          value={formData.title}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>
                          Description
                          <i className="fal fa-info-circle" />
                        </label>
                        <input
                          type="text"
                          placeholder="Description"
                          name="description"
                          value={formData.description}
                          onChange={handleInputChange}
                          defaultValue=""
                        />
                      </div>
                      <div className="col-md-4">
                        <label>Type of listing</label>
                        <div className="listsearch-input-item">
                          <select
                            value={formData.listing_type}
                            name="listing_type"
                            onChange={handleInputChange}
                            data-placeholder="Apartments"
                            className="nice-select chosen-select no-search-select"
                            // style={{ display: "none" }}
                          >
                            <option>All Categories</option>
                            <option className="list">Shops</option>
                            <option>Hotels</option>
                            <option>Restaurants</option>
                            <option>Fitness</option>
                            <option>Events</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <label>
                          Number of Bedrooms (N)
                          <i className="fal fa-bed" />
                        </label>
                        <input
                          type="number"
                          placeholder="Number of Bedrooms"
                          name="no_of_bedroom"
                          value={formData.no_of_bedroom}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>
                          Number of Guests (N)
                          <i className="fal fa-user-friends" />
                        </label>
                        <input
                          type="number"
                          placeholder="Number of Guests"
                          name="no_of_guest"
                          value={formData.no_of_guest}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>
                          Number of Beds (N)
                          <i className="fal fa-bed" />
                        </label>
                        <input
                          id="no_of_bed"
                          type="number"
                          placeholder="Number of Beds"
                          name="no_of_bed"
                          value={formData.no_of_bed}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>
                          Number of Bathrooms (N)
                          <i className="fal fa-bath" />
                        </label>
                        <input
                          type="number"
                          placeholder="Number of Bathrooms"
                          name="no_of_bathroom"
                          value={formData.no_of_bathroom}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>
                          Number of Rooms (N)
                          <i className="fal fa-door-open" />
                        </label>
                        <input
                          type="number"
                          placeholder="Number of Rooms"
                          name="no_of_room"
                          value={formData.no_of_room}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>
                          Size (N)
                          <i className="fal fa-ruler" />
                        </label>
                        <input
                          type="number"
                          placeholder="Size"
                          name="size"
                          value={formData.size}
                          onChange={handleInputChange}
                        />
                      </div>
                      {/* hogya */}
                      <div className="col-md-12">
                        <div
                          className="profile-edit-container fl-wrap block_box"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="custom-form"
                            style={{ paddingLeft: "20px", paddingTop: "10px" }}
                          >
                            {/* Checkboxes */}
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <li>
                                <input
                                  id="check-aaa5"
                                  type="checkbox"
                                  name="instant_booking"
                                  checked={formData.instant_booking === 1}
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="check-aaa5">
                                  Allow instant booking for this space.
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label>
                          Unit of Measure (N)
                          <i className="fal fa-ruler" />
                        </label>
                        <input
                          type="number"
                          placeholder="Unit of Measure"
                          defaultValue=""
                          name="unit_of_measure"
                          value={formData.unit_of_measure}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>
                          Additional Information
                          <i className="fal fa-info-circle" />
                        </label>
                        <input
                          type="text"
                          placeholder="Additional Information"
                          defaultValue=""
                          name="addi_info"
                          value={formData.addi_info}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="col-md-4">
                        <label>
                          Nightly Price (N)
                          <i className="fal fa-moon-stars" />
                        </label>
                        <input
                          type="number"
                          placeholder="Nightly Price"
                          defaultValue=""
                          name="nightly"
                          value={formData.nightly}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>
                          Per
                          <i className="fal fa-calendar-alt" />
                        </label>
                        <input
                          type="text"
                          placeholder="Per"
                          defaultValue=""
                          name="per"
                          value={formData.per}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>Apply Weekend Price </label>
                        <div className="listsearch-input-item">
                          <select
                            value={formData.apply_weekend_price}
                            onChange={handleInputChange}
                            name="apply_weekend_price"
                            // data-placeholder="Apartments"
                            className="nice-select chosen-select no-search-select"
                            // style={{ display: "none" }}
                          >
                            <option value={"Saturday and Sunday"}>
                              Saturday and Sunday
                            </option>
                            <option value={"Monday and Tuesday"}>
                              Monday and Tuesday
                            </option>
                            <option value={"Wednesday and Thursday"}>
                              Wednesday and Thursday
                            </option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <label>
                          Weekend (N)
                          <i className="fal fa-check-square" />
                        </label>
                        <input
                          type="number"
                          placeholder="Apply Weekend Price"
                          defaultValue=""
                          name="weekend"
                          value={formData.weekend}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <label>
                          Weekly (7+ Days) Price (N)
                          <i className="fal fa-calendar-week" />
                        </label>
                        <input
                          type="number"
                          placeholder="Weekly (7+ Days) Price"
                          name="weekly_seven_plus"
                          value={formData.weekly_seven_plus}
                          onChange={handleInputChange}
                          defaultValue=""
                        />
                      </div>
                      <div className="col-md-6">
                        <label>
                          Weekly (30+ Days) Price (N)
                          <i className="fal fa-calendar-alt" />
                        </label>
                        <input
                          type="number"
                          placeholder="Weekly (30+ Days) Price"
                          name="weekly_thirty_plus"
                          value={formData.weekly_thirty_plus}
                          onChange={handleInputChange}
                          defaultValue=""
                        />
                      </div>

                      {/* Multiple Input Code Start */}
                      <div>
                        {extraServicePrices?.map((price, index) => (
                          <div className="row" key={index}>
                            <div className="col-md-4">
                              <label>Extra Service Prices Name</label>
                              <input
                                type="text"
                                placeholder="Extra Service Name"
                                value={price.name}
                                onChange={(e) =>
                                  handleEdit(
                                    index,
                                    "name",
                                    e.target.value,
                                    price?.id
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <label>Extra Service Prices Price (N)</label>
                              <input
                                type="number"
                                placeholder="Extra Service Prices"
                                value={price.price}
                                onChange={(e) =>
                                  handleEdit(
                                    index,
                                    "price",
                                    e.target.value,
                                    price?.id
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <label>Extra Service Prices Type</label>
                              <select
                                data-placeholder="Apartments"
                                className="nice-select chosen-select no-search-select"
                                value={price.type}
                                onChange={(e) =>
                                  handleEdit(
                                    index,
                                    "type",
                                    e.target.value,
                                    price?.id
                                  )
                                }
                              >
                                <option value="">All Categories</option>
                                <option value="Shops">Shops</option>
                                <option value="Hotels">Hotels</option>
                                <option value="Restaurants">Restaurants</option>
                                <option value="Fitness">Fitness</option>
                                <option value="Events">Events</option>
                              </select>
                            </div>
                            {extraServicePrices.length > 1 && (
                              <div className="col-md-12">
                                <div
                                  className="btn btn-danger cursor-pointer"
                                  style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    marginBottom: "20px",
                                    marginTop: "0px",
                                    width: "170px",
                                  }}
                                  onClick={() => handleRemove(index, price)}
                                >
                                  Remove Service
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                        <div
                          className="col-md-12"
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            marginBottom: "30px",
                            marginRight: "20px",
                          }}
                        >
                          <div
                            className="btn btn-primary cursor-pointer"
                            onClick={handleAdd}
                            style={{
                              marginRight: "20px",
                            }}
                          >
                            Add Service
                            <i className="fas fa-sign-out" />
                          </div>
                        </div>
                      </div>
                      {/* Multiple Input Code End */}

                      {/* hogya */}
                      <div className="col-md-12 d- flex justify-content-spacebetween">
                        <label style={{ paddingRight: "10px" }}>
                          Additional Guest
                        </label>
                        <div className="col-md-6">
                          <div
                            className="profile-edit-container fl-wrap block_box"
                            style={{ padding: "0px" }}
                          >
                            <div
                              className="custom-form"
                              style={{
                                paddingLeft: "20px",
                                paddingTop: "10px",
                              }}
                            >
                              {/* Checkboxes */}
                              <ul className="fl-wrap filter-tags no-list-style ds-tg">
                                <li>
                                  <input
                                    id="addi_guest"
                                    type="radio"
                                    name="addi_guest"
                                    value={1}
                                    checked={formData.addi_guest == 1}
                                    onChange={handleInputChange}
                                  />
                                  <label htmlFor="addi_guest">Yes</label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className="profile-edit-container fl-wrap block_box"
                            style={{ padding: "0px" }}
                          >
                            <div
                              className="custom-form"
                              style={{
                                paddingLeft: "20px",
                                paddingTop: "10px",
                              }}
                            >
                              {/* Checkboxes */}
                              <ul className="fl-wrap filter-tags no-list-style ds-tg">
                                <li>
                                  <input
                                    id="addi_guest"
                                    type="radio"
                                    name="addi_guest"
                                    value="No"
                                    checked={formData.addi_guest === "No"}
                                    onChange={handleInputChange}
                                  />
                                  <label htmlFor="addi_guest">No</label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-8">
                        <label>
                          Additional Guest Price (N)
                          <i className="fal fa-dollar-sign" />
                        </label>
                        <input
                          type="number"
                          placeholder="Additional Guest Price"
                          name="addi_guest_price"
                          value={formData.addi_guest_price}
                          onChange={handleInputChange}
                          defaultValue=""
                        />
                      </div>
                      <div className="col-md-4">
                        <label>
                          Number of Additional Guests (N)
                          <i className="fal fa-users" />
                        </label>
                        <input
                          type="number"
                          placeholder="Number of Additional Guests"
                          defaultValue=""
                          name="no_of_addi_guest"
                          value={formData.no_of_addi_guest}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>
                          Cleaning Fee (N)
                          <i className="fal fa-broom" />
                        </label>
                        <input
                          type="number"
                          placeholder="Cleaning Fee"
                          name="clean_fee"
                          value={formData.clean_fee}
                          onChange={handleInputChange}
                          defaultValue=""
                        />
                      </div>
                      <div className="col-md-8 d- flex justify-content-spacebetween">
                        <label style={{ paddingRight: "10px" }}>
                          Daily and Per Stay Fee
                        </label>
                        <div className="col-md-6">
                          <div
                            className="profile-edit-container fl-wrap block_box"
                            style={{ padding: "0px" }}
                          >
                            <div
                              className="custom-form"
                              style={{
                                paddingLeft: "20px",
                                paddingTop: "10px",
                              }}
                            >
                              {/* Checkboxes */}
                              <ul className="fl-wrap filter-tags no-list-style ds-tg">
                                <li>
                                  <input
                                    id="clean_fee_daily"
                                    type="radio"
                                    name="clean_fee_daily"
                                    value="Daily"
                                    checked={formData.clean_fee_daily}
                                    onChange={handleInputChange2}
                                  />
                                  <label htmlFor="clean_fee_daily">Daily</label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className="profile-edit-container fl-wrap block_box"
                            style={{ padding: "0px" }}
                          >
                            <div
                              className="custom-form"
                              style={{
                                paddingLeft: "20px",
                                paddingTop: "10px",
                              }}
                            >
                              {/* Checkboxes */}
                              <ul className="fl-wrap filter-tags no-list-style ds-tg">
                                <li>
                                  <input
                                    id="clean_fee_per_stay"
                                    type="radio"
                                    name="clean_fee_per_stay"
                                    value="Per Stay"
                                    checked={formData.clean_fee_per_stay}
                                    onChange={handleInputChange2}
                                  />
                                  <label htmlFor="clean_fee_per_stay">
                                    Per Stay
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label>
                          City Fee (N)
                          <i className="fal fa-city" />
                        </label>
                        <input
                          type="number"
                          placeholder="City Fee"
                          defaultValue=""
                          name="city_fee"
                          value={formData.city_fee}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-8 d- flex justify-content-spacebetween">
                        <label style={{ paddingRight: "10px" }}>
                          Daily and Per Stay Fee
                        </label>
                        <div className="col-md-6">
                          <div
                            className="profile-edit-container fl-wrap block_box"
                            style={{ padding: "0px" }}
                          >
                            <div
                              className="custom-form"
                              style={{
                                paddingLeft: "20px",
                                paddingTop: "10px",
                              }}
                            >
                              {/* Checkboxes */}
                              <ul className="fl-wrap filter-tags no-list-style ds-tg">
                                <li>
                                  <input
                                    id="city_fee_daily"
                                    type="radio"
                                    name="city_fee_daily"
                                    value="Daily"
                                    checked={formData.city_fee_daily}
                                    onChange={handleInputChange1}
                                  />
                                  <label htmlFor="city_fee_daily">Daily</label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className="profile-edit-container fl-wrap block_box"
                            style={{ padding: "0px" }}
                          >
                            <div
                              className="custom-form"
                              style={{
                                paddingLeft: "20px",
                                paddingTop: "10px",
                              }}
                            >
                              {/* Checkboxes */}
                              <ul className="fl-wrap filter-tags no-list-style ds-tg">
                                <li>
                                  <input
                                    id="city_fee_per_stay"
                                    type="radio"
                                    name="city_fee_per_stay"
                                    value="Per Stay"
                                    checked={formData.city_fee_per_stay}
                                    onChange={handleInputChange1}
                                  />
                                  <label htmlFor="city_fee_per_stay">
                                    Per Stay
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label>
                          Security Deposit (N)
                          <i className="fal fa-shield-alt" />
                        </label>
                        <input
                          name="security_deposit"
                          type="number"
                          placeholder="Security Deposit"
                          // defaultValue=""
                          value={formData.security_deposit}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>
                          Tax (N)
                          <i className="fal fa-percent" />
                        </label>
                        <input
                          type="number"
                          name="tax"
                          value={formData.tax}
                          onChange={handleInputChange}
                          placeholder="Tax"
                          defaultValue=""
                        />
                      </div>
                      {/* hogya  */}
                    </div>
                    <div className="dashboard-title  dt-inbox fl-wrap">
                      <h3>Amenities</h3>
                    </div>
                    <div className="list-single-main-item_content fl-wrap">
                        <div className="listing-features fl-wrap">
                          <ul className="no-list-style">
                            {getone?.map((amenity, index) => (
                              <li key={index}>
                                <Link to="#">
                                  <i className="fa fa-rocket" /> {amenity}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    <div className="dashboard-title  dt-inbox fl-wrap">
                      <h3>Facilities</h3>
                    </div>
                    <div className="list-single-main-item_content fl-wrap">
                        <div className="listing-features fl-wrap">
                          <ul className="no-list-style">
                            {get?.map((amenity, index) => (
                              <li key={index}>
                                <Link to="#">
                                  <i className="fa fa-rocket" /> {amenity}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    <div className="col-md-4">
                      <label>
                        Address
                        <i className="fal fa-map-marker-alt" />
                      </label>
                      <input
                        type="text"
                        placeholder="Address"
                        onChange={handleInputChange}
                        name="address"
                        value={formData.address}
                        defaultValue=""
                      />
                    </div>
                    <div className="col-md-4">
                      <label>
                        Apartment/Suite
                        <i className="fal fa-building" />
                      </label>
                      <input
                        type="text"
                        name="apt_suite"
                        value={formData.apt_suite}
                        onChange={handleInputChange}
                        placeholder="Apartment/Suite"
                        defaultValue=""
                      />
                    </div>
                    <div className="col-md-4">
                      <label>
                        City
                        <i className="fal fa-city" />
                      </label>
                      <input
                        type="text"
                        name="city"
                        value={formData.city}
                        placeholder="City"
                        onChange={handleInputChange}
                        defaultValue=""
                      />
                    </div>
                    <div className="col-md-4">
                      <label>
                        State
                        <i className="fal fa-map" />
                      </label>
                      <input
                        type="text"
                        name="state"
                        value={formData.state}
                        onChange={handleInputChange}
                        placeholder="State"
                        defaultValue=""
                      />
                    </div>
                    <div className="col-md-4">
                      <label>
                        Zipcode
                        <i className="fal fa-mail-bulk" />
                      </label>
                      <input
                        type="text"
                        name="zipcode"
                        value={formData.zipcode}
                        onChange={handleInputChange}
                        placeholder="Zipcode"
                        defaultValue=""
                      />
                    </div>
                    <div className="col-md-4">
                      <label>
                        Area
                        <i className="fal fa-map-marked-alt" />
                      </label>
                      <input
                        type="text"
                        name="area"
                        value={formData.area}
                        onChange={handleInputChange}
                        placeholder="Area"
                        defaultValue=""
                      />
                    </div>
                    <div className="col-md-4">
                      <label>
                        Country
                        <i className="fal fa-globe" />
                      </label>
                      <input
                        type="text"
                        name="country"
                        value={formData.country}
                        onChange={handleInputChange}
                        placeholder="Country"
                        defaultValue=""
                      />
                    </div>

                    <div className="col-md-4">
                      <label>
                        Cancellation Policy
                        <i className="fal fa-times-circle" />
                      </label>
                      <div className="listsearch-input-item">
                        <select
                          value={formData.cancellation_policy}
                          name="cancellation_policy"
                          onChange={handleInputChange}
                          data-placeholder="Apartments"
                          className="nice-select chosen-select no-search-select"
                          // style={{ display: "none" }}
                        >
                          <option>cancellation policy</option>
                          <option>Shops</option>
                          <option>Hotels</option>
                          <option>Restaurants</option>
                          <option>Fitness</option>
                          <option>Events</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <label>
                        Minimum Day Booking
                        <i className="fal fa-calendar-minus" />
                      </label>
                      <input
                        type="text"
                        name="min_day_booking"
                        value={formData.min_day_booking}
                        onChange={handleInputChange}
                        placeholder="Minimum Day Booking"
                        defaultValue=""
                      />
                    </div>
                    <div className="col-md-4" style={{ float: "right" }}>
                      <label>
                        Maximum Day Booking
                        <i className="fal fa-calendar-plus" />
                      </label>
                      <input
                        name="max_day_booking"
                        value={formData.max_day_booking}
                        onChange={handleInputChange}
                        type="text"
                        placeholder="Maximum Day Booking"
                        defaultValue=""
                      />
                    </div>
                    <div className="col-md-4">
                      <label>
                        Check-in After
                        <i className="fal fa-clock" />
                      </label>
                      <div className="listsearch-input-item">
                        <select
                          value={formData.check_in_after}
                          name="check_in_after"
                          onChange={handleInputChange}
                          data-placeholder="Apartments"
                          className="nice-select chosen-select no-search-select"
                          // style={{ display: "none" }}
                        >
                          <option>Select</option>
                          <option>8:00PM</option>
                          <option>8:30PM</option>
                          <option>9:00PM</option>
                          <option>9:30PM</option>
                          <option>10:00PM</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <label>
                        Check-in Before
                        <i className="fal fa-clock" />
                      </label>
                      <div className="listsearch-input-item">
                        <select
                          value={formData.check_in_before}
                          name="check_in_before"
                          onChange={handleInputChange}
                          data-placeholder="Apartments"
                          className="nice-select chosen-select no-search-select"
                          // style={{ display: "none" }}
                        >
                          <option>Select</option>
                          <option>8:00PM</option>
                          <option>8:30PM</option>
                          <option>9:00PM</option>
                          <option>9:30PM</option>
                          <option>10:00PM</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-12 d-flex justify-content-spacebetween">
                      <label style={{ paddingRight: "10px" }}>
                        Smoking Allowed
                      </label>
                      <div className="col-md-6">
                        <div
                          className="profile-edit-container fl-wrap block_box"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="custom-form"
                            style={{ paddingLeft: "20px", paddingTop: "10px" }}
                          >
                            {/* Checkboxes */}
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <li>
                                <input
                                  id="smoking_allowed"
                                  type="radio"
                                  name="smoking_allowed"
                                  value="Yes"
                                  checked={formData.smoking_allowed === "Yes"}
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="smoking_allowed">Yes</label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="profile-edit-container fl-wrap block_box"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="custom-form"
                            style={{ paddingLeft: "20px", paddingTop: "10px" }}
                          >
                            {/* Checkboxes */}
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <li>
                                <input
                                  id="smoking_allowed"
                                  type="radio"
                                  name="smoking_allowed"
                                  value="No"
                                  checked={formData.smoking_allowed === "No"}
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="smoking_allowed">No</label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 d- flex justify-content-spacebetween">
                      <label style={{ paddingRight: "10px" }}>
                        Pet Allowed
                      </label>
                      <div className="col-md-6">
                        <div
                          className="profile-edit-container fl-wrap block_box"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="custom-form"
                            style={{ paddingLeft: "20px", paddingTop: "10px" }}
                          >
                            {/* Checkboxes */}
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <li>
                                <input
                                  id="pet_allowed"
                                  type="radio"
                                  name="pet_allowed"
                                  value="Yes"
                                  checked={formData.pet_allowed === "Yes"}
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="pet_allowed">Yes</label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="profile-edit-container fl-wrap block_box"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="custom-form"
                            style={{ paddingLeft: "20px", paddingTop: "10px" }}
                          >
                            {/* Checkboxes */}
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <li>
                                <input
                                  id="pet_allowed"
                                  type="radio"
                                  name="pet_allowed"
                                  value="No"
                                  checked={formData.pet_allowed === "No"}
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="pet_allowed">No</label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 d- flex justify-content-spacebetween">
                      <label style={{ paddingRight: "10px" }}>
                        Party Allowed
                      </label>
                      <div className="col-md-6">
                        <div
                          className="profile-edit-container fl-wrap block_box"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="custom-form"
                            style={{ paddingLeft: "20px", paddingTop: "10px" }}
                          >
                            {/* Checkboxes */}
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <li>
                                <input
                                  id="party_allowed"
                                  type="radio"
                                  name="party_allowed"
                                  value="Yes"
                                  checked={formData.party_allowed === "Yes"}
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="party_allowed">Yes</label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="profile-edit-container fl-wrap block_box"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="custom-form"
                            style={{ paddingLeft: "20px", paddingTop: "10px" }}
                          >
                            {/* Checkboxes */}
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <li>
                                <input
                                  id="party_allowed"
                                  type="radio"
                                  name="party_allowed"
                                  value="No"
                                  checked={formData.party_allowed === "No"}
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="party_allowed">No</label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 d- flex justify-content-spacebetween">
                      <label style={{ paddingRight: "10px" }}>
                        Children Allowed
                      </label>
                      <div className="col-md-6">
                        <div
                          className="profile-edit-container fl-wrap block_box"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="custom-form"
                            style={{ paddingLeft: "20px", paddingTop: "10px" }}
                          >
                            {/* Checkboxes */}
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <li>
                                <input
                                  id="children_allowed"
                                  type="radio"
                                  name="children_allowed"
                                  value="True"
                                  checked={formData.children_allowed === "True"}
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="children_allowed">Yes</label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="profile-edit-container fl-wrap block_box"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className="custom-form"
                            style={{ paddingLeft: "20px", paddingTop: "10px" }}
                          >
                            {/* Checkboxes */}
                            <ul className="fl-wrap filter-tags no-list-style ds-tg">
                              <li>
                                <input
                                  id="children_allowed"
                                  type="radio"
                                  name="children_allowed"
                                  value="false"
                                  checked={
                                    formData.children_allowed === "false"
                                  }
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="children_allowed">No</label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Multiple Input Code Start */}
                    {listing_Beds?.map((bedroom, index) => (
                      <div className="row" key={index}>
                        <div className="col-md-6">
                          <label>Name of Bedroom {index + 1}</label>
                          <input
                            type="text"
                            value={bedroom.name}
                            onChange={(e) =>
                              handleInputChangeBeds(
                                e.target.value,
                                index,
                                "name",
                                bedroom?.id
                              )
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <label>Number of Guests</label>
                          <input
                            type="number"
                            value={bedroom.no_of_guest}
                            onChange={(e) =>
                              handleInputChangeBeds(
                                e.target.value,
                                index,
                                "no_of_guest",
                                bedroom?.id
                              )
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <label>Number of Beds</label>
                          <input
                            type="number"
                            value={bedroom.no_of_bed}
                            onChange={(e) =>
                              handleInputChangeBeds(
                                e.target.value,
                                index,
                                "no_of_bed",
                                bedroom?.id
                              )
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <label>Number of Type</label>
                          <input
                            type="number"
                            value={bedroom.bed_type}
                            onChange={(e) =>
                              handleInputChangeBeds(
                                e.target.value,
                                index,
                                "bed_type",
                                bedroom?.id
                              )
                            }
                          />
                        </div>
                        {listing_Beds?.length > 1 && (
                          <div
                            className="btn btn-danger cursor-pointer"
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              marginBottom: "20px",
                              marginLeft: "20px",
                              width: "180px",
                            }}
                            onClick={() => handleMinusClickBeds(index, bedroom)}
                          >
                            Remove Bedroom
                          </div>
                        )}
                      </div>
                    ))}

                    <div
                      className="col-md-12"
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginBottom: "30px",
                      }}
                    >
                      <div
                        className="logout_btn color2-bg cursor-pointer"
                        onClick={handlePlusClickBeds}
                      >
                        Add New Bedroom
                        <i className="fas fa-sign-out" />
                      </div>
                    </div>

                    {/* Multiple Input Code End */}

                    <div className="col-md-8">
                      <label>House Rules Additional Information</label>
                      <textarea
                        cols={40}
                        name="house_rule_addi_info"
                        rows={3}
                        onChange={handleInputChange}
                        placeholder="Information"
                        defaultValue={""}
                      />
                    </div>

                    <div className="col-md-4">
                      <ImageUploading
                        multiple
                        value={listing_Images}
                        onChange={onChange}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                        acceptType={["jpg"]}
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          <div className="upload__image-wrapper">
                            <div
                              className="fu-text"
                              style={isDragging ? { color: "red" } : null}
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              <div className="add-list-media-wrap">
                                <div className="listsearch-input-item fl-wrap">
                                  <div className="fuzone">
                                    <form>
                                      <div className="fu-text">
                                        <span>
                                          <i className="fal fa-images" /> Click
                                          here or drop files to upload
                                        </span>
                                        <div className="photoUpload-files fl-wrap" />
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                              <div className="photoUpload-files fl-wrap" />
                            </div>
                            <div
                              className="row"
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              {listing_Images?.map((image, index) => (
                                <div key={index} className="image-item">
                                  <img
                                    src={image?.data_url || image?.image}
                                    alt=""
                                    style={{
                                      width: "100px",
                                      height: "80px",
                                      marginBottom: "12px",
                                      marginTop: "10px",
                                    }}
                                  />
                                  <div
                                    className="image-item__btn-wrapper"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      gap: "10px",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      className="btn btn-danger cursor-pointer"
                                      style={{ width: "90%" }}
                                      onClick={() =>
                                        onImageUpdate(index, image?.id)
                                      }
                                    >
                                      Update
                                    </div>
                                    <div
                                      style={{ width: "90%" }}
                                      className="btn btn-danger cursor-pointer"
                                      onClick={() =>
                                        onImageRemoves(index, image)
                                      }
                                    >
                                      Remove
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </ImageUploading>
                    </div>
                  </div>
                </div>
                {/*add_room-item end  */}
              </div>
              <button
                className="logout_btn color2-bg"
                style={{ width: "150px" }}
                type="submit"
              >
                {loader ? (
                  <BtnLoader />
                ) : (
                  <>
                    Edit Add <i className="fas fa-sign-out" />
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default Edit;
