/* eslint-disable */
import BtnLoader from "./BtnLoader/BtnLoader";
import React, { useState } from "react";
// import Swal from "sweetalert2";
import axios from "axios";
import BASE_URL from "./auth/Baseurl";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import getProfile from "./getProfile";
import Swal from "sweetalert2";
function ProfileRight() {
  const navigate = useNavigate();
  const [loaders, setLoaders] = useState(false);
  const [image, setImage] = useState(null);
  const [data, setData] = useState(JSON.parse(localStorage.getItem("data")));
  const [formdata, setFormdata] = useState({
    first_name: data?.first_name || "",
    last_name: data?.last_name || "",
    email: data?.email || "",
    username: data?.username || "",
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? (checked ? 1 : 0) : value;

    setFormdata((prevState) => ({
      ...prevState,
      [name]: inputValue,
    }));
  };
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };
  // console.log(image, "value");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoaders(true);
    console.log(formdata, ">>>");
    const formData = new FormData();

    formData.append("role", data?.role);
    formData.append("first_name", formdata.first_name);
    formData.append("last_name", formdata.last_name);
    formData.append("email", formdata.email);
    formData.append("username", formdata.username);
    formData.append("image", image);

    try {
      const response = await axios.post(
        `${BASE_URL}/host/${data.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // console.log(response?.data, "dikhao");
      // Handle successful response
      console.log(response);

      getProfile().then((data) => {
        localStorage.setItem("data", JSON.stringify(data?.user));
      });
      setLoaders(false);
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Profile Update successful:",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/dashboard");
    } catch (err) {
      setLoaders(false);

      toast.error("Something went wrong!")
     
    }
  };
  return (
    <div className="col-md-9">
      <div className="dashboard-title fl-wrap">
        <h3>Your Profile</h3>
      </div>
      {/* profile-edit-container*/}
      <div className="profile-edit-container fl-wrap block_box">
        <div className="custom-form">
          <div className="row">
            <div className="col-sm-6">
              <label>
                First Name <i className="fal fa-user" />
              </label>
              <input
                type="text"
                name="first_name"
                onChange={handleInputChange}
                value={formdata.first_name}
                placeholder="Jessie"
              />
            </div>
            <div className="col-sm-6">
              <label>
                Last Name <i className="fal fa-user" />
              </label>
              <input
                type="text"
                placeholder="Manrty"
                name="last_name"
                onChange={handleInputChange}
                value={formdata.last_name}
              />
            </div>
            <div className="col-sm-6">
              <label>
                User Name <i className="fal fa-user" />
              </label>
              <input
                type="text"
                placeholder="Manrty"
                name="username"
                onChange={handleInputChange}
                value={formdata.username}
              />
            </div>
            <div className="col-sm-6">
              <label>
                Email Address
                <i className="far fa-envelope" />
              </label>
              <input
                type="text"
                placeholder="JessieManrty@domain.com"
                name="email"
                onChange={handleInputChange}
                value={formdata.email}
              />
            </div>

            <div className="clearfix" />
            <label>Change Avatar</label>
            <div className="clearfix" />
            <div className="listsearch-input-item fl-wrap">
              <div className="fuzone">
                <form>
                  <div className="fu-text">
                    <span>
                      <i className="fal fa-images" /> Click here or drop files
                      to upload
                    </span>
                    <div className="photoUpload-files fl-wrap" />
                  </div>
                  <input
                    type="file"
                    className="upload"
                    onChange={handleImageChange}
                    multiple=""
                  />
                </form>
              </div>
            </div>
            <button
              className="btn    color2-bg  float-btn"
              onClick={handleSubmit}
            >
              {loaders ? (
                <BtnLoader />
              ) : (
                <>
                  <span>Save Changes</span>
                  <i className="fal fa-save" />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
      {/* profile-edit-container end*/}

      {/* profile-edit-container end*/}
    </div>
  );
}

export default ProfileRight;
