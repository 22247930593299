import React from 'react'
import Sidebar from '../Components/Sidebar'
import BannerDashboard from '../Components/BannerDashboard'
import ChangePRight from '../Components/ChangePRight'
import Layout from '../Components/layout/Layout'

function Changepassword() {
  
  return (
    <Layout>

    <div id="wrapper">
  {/* content*/}
  <div className="content">
    {/*  section  */}
<BannerDashboard/>
    {/*  section  end*/}
    {/*  section  */}
    <section className="gray-bg main-dashboard-sec" id="sec1">
      <div className="container">
        {/*  dashboard-menu*/}
        <Sidebar/>
        {/* dashboard-menu  end*/}
        {/* dashboard content*/}
     <ChangePRight/>
        {/* dashboard content end*/}
      </div>
    </section>
    {/*  section  end*/}
    <div className="limit-box fl-wrap" />
  </div>
  {/*content end*/}
</div>
</Layout>

  )
}

export default Changepassword