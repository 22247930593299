
const TermsAndCondition = () => {
    return (
      <div style={{minHeight:'100vh',padding:'15px'}}>
          <div style={{paddingTop:'10px'}}>
          <h1>Terms and Conditions</h1>
          </div>
          <div>
              <p style={{textAlign:'start',marginTop:'20px'}}>
              1. Introduction By using our app, you agree to these Terms and Conditions. If you do not agree, please refrain from using the app.
              </p>
          
          </div>
  
          <div>
              <p style={{textAlign:'start',marginTop:'20px'}}>
              2. License to Use We grant you a limited, non-exclusive, and revocable license to use the app for personal, non-commercial purposes. You must not: <br/>
Modify, copy, or distribute any content from the app without permission.
Use the app in an unlawful manner.
              </p>
              
          </div>
  
          <div>
              <p style={{textAlign:'start',marginTop:'20px'}}>
              3. User Responsibilities You are responsible for ensuring the accuracy of the information you provide and for safeguarding your account credentials. You agree not to misuse the app or access unauthorized areas.
              </p>
              
          </div>
  
  
          <div>
              <p style={{textAlign:'start',marginTop:'20px'}}>
              4. App Content While we strive for accuracy, we do not guarantee that the information provided in the app, including chalet listings, is always correct, complete, or up-to-date.
              </p>
             
          </div>
  
          <div>
              <p style={{textAlign:'start',marginTop:'20px'}}>
              5. Limitation of Liability We are not responsible for any indirect or direct damages resulting from the use or inability to use the app. The app is provided “as is,” without warranties of any kind.
              </p>
          
          </div>
  
          <div>
              <p style={{textAlign:'start',marginTop:'20px'}}>
              6. Termination We reserve the right to terminate or suspend your access to the app if you violate these Terms and Conditions.
              </p>
             
          </div>
  
          <div>
              <p style={{textAlign:'start',marginTop:'20px'}}>
              7. Changes to the Terms We may update these Terms from time to time. Continued use of the app after any changes means you agree to the updated Terms.
              </p>
            
          </div>
  
      </div>
    )
  }
  
  export default TermsAndCondition
  